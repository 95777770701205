const LoginRegisterButtons = (props) => {
  return (
    <>
      <a className={'btn'} onClick={props.onLoginDialogOpen}>
        Giriş Yap / Üye Ol
      </a>
      <style jsx>{`
        .btn {
          font-size: 14px;
          font-weight: 500;
          line-height: 24px;
          border-radius: 5px;
          border-color: #e0212b;
          background-color: #fff;
          color: #e0212b;

          &:hover,
          &:focus {
            text-decoration: underline;
            color: #e0212b;
          }

          @media (max-width: 992px) {
            padding: 0;
            margin: 0;
            background-color: #fff;
            color: #e0212b;
            font-weight: 400;
            border: none;
          }

          @media (max-width: 360px) {
            max-width: 62px;
            white-space: nowrap;
            overflow: hidden;
          }
        }
      `}</style>
    </>
  )
}

export default LoginRegisterButtons
